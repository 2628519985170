// Imports
var ___HTML_LOADER_IMPORT_0___ = new URL("/static/img/logo_white.png", import.meta.url);
var ___HTML_LOADER_IMPORT_1___ = new URL("/static/img/cadenas.svg", import.meta.url);
// Module
var code = `<template>
  <require from="./payments_recap.scss"></require>
  <require from="../../../components/ValueConverters/iban-converter"></require>
  <require from="../../../components/date-format-short"></require>
  <require from="../../../components/loader-ring/loader-ring"></require>
  <require from="../../../components/number-format-bsv"></require>
  <require from="../../../components/ValueConverters/number-format-eur"></require>
  <require from="../../../components/ValueConverters/currency-denomination"></require>

  <section id="payments_recap" class="flex-column">
    <div id="header" class="flex-row">
    </div>
    <div class="newlayout">
      <div id="sender_section" class="chic flex-column">
        <p class="left" t="payments.recap.sender">From</p>
        <div class="flex-grow"></div>
        <div class="right flex-column">
          <div class="right_row1 flex-row">
            <div class="img_container">
              <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="!state.wallets.picture" />
              <img src.bind="state.wallets.picture" if.bind="state.wallets.picture">
            </div>
            <div class="name_iban_date flex-column">
              <p class="name"  style="white-space: nowrap;">\${state.makePayment.sender.name}</p>
              <p class="desc"  style="white-space: nowrap;" if.bind="!isTopUp && !state.makePayment.sender.isBlockchain">\${state.makePayment.sender.identification | ibanConverter}</p>
              <p class="desc"  style="white-space: nowrap;" if.bind="state.makePayment.sender.isBlockchain || state.makePayment.recipient.identification.includes('@')">\${state.makePayment.sender.identification}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="receiver_section" class="chic flex-column">
        <p class="left" t="payments.recap.receiver"></p>
        <div class="flex-grow"></div>
        <div class="right flex-column">
          <div class="right_row1 flex-row">
            <div class="img_container">
              <img src="${___HTML_LOADER_IMPORT_0___}" if.bind="!profileToDisplay.avatar" />
              <img src.bind="profileToDisplay.avatar" if.bind="profileToDisplay.avatar" />
            </div>
            <div class="name_iban_date flex-column">
              <p id="payment_receiver_when_sender_is_bitcoin" class="name" style="white-space: nowrap;"  if.bind="state.makePayment.sender.isBlockchain">\${selectedRecipient.name}</p>
              <p id="payment_receiver_when_sender_is_bank"    class="name" style="white-space: nowrap;"  if.bind="!(state.makePayment.sender.isBlockchain)">\${state.makePayment.recipient.name}</p>
              <p id="topup_when_receiver_is_bank"    class="desc"  style="white-space: nowrap;" if.bind="!isTopUp && !state.makePayment.sender.isBlockchain">\${state.makePayment.recipient.identification | ibanConverter}</p>
              <p id="topup_when_receiver_is_bitcoin" class="desc"  style="white-space: nowrap;" if.bind="state.makePayment.sender.isBlockchain || state.makePayment.recipient.identification.includes('@')">\${selectedRecipient.identification}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="chic flex-column">
        <p class="left" t="payments.recap.commentary">Comment</p>
        <div class="flex-grow"></div>
        <div class="right">
          <p class="comment" if.bind="!(state.makePayment.sender.isBlockchain)">\${state.makePayment.note}</p>
          <p class="comment" if.bind="state.makePayment.sender.isBlockchain">\${comment}</p>
        </div>
      </div>

      <div id="feeSectionBSV" class="chic flex-column" if.bind="state.makePayment.sender.isBlockchain">
        <div id="left_amount" class="left" style="padding-bottom: 0.5rem;">
          <p class="left desc" t="payments.recap.amount">Amount</p>
        </div>
        <div class="flex-grow"></div>
        <div id="right_amount" class="right">
            <div>
            <p id="amount_2" class="amount" if.bind="!state.makePayment.sender.isBlockchain">\${amount} \${currency |currencyDenomination}</p>
            </div>
          <div id="right_mybanka_fee" if.bind="isTopUp">
            <p class="fee" if.bind="state.makePayment.sender.isBlockchain"> \${currency |currencyDenomination} \${feeAmount}</p>
            <p class="fee" if.bind="!state.makePayment.sender.isBlockchain">\${feeAmount} \${currency |currencyDenomination}</p>
          </div>
          <div id="right_amount_bsv" if.bind="state.makePayment.sender.isBlockchain">
            <p class="fee">\${currency |currencyDenomination} \${amount}</p>
          </div>
        </div>
      </div>

      <div id="ChicIsBlockchain MinerFee" class="chic flex-column" if.bind="state.makePayment.sender.isBlockchain">
        <div id="left_minerfee" class="left" style="padding-bottom: 0.5rem;">
          <p class="left desc">Minerfee</p>
        </div>
        <div id="right_minerfee" class="right">
          <p id="amount_1" class="amount" style="width:100%; text-align: left;"> \${currency |currencyDenomination} \${minerFee}</p>
        </div>
      </div>

      <div id="feeSectionNonBSV" class="chic flex-column" if.bind="!state.makePayment.sender.isBlockchain && isTopUp">
        <div id="left_amount">
          <p class="left" t="payments.recap.amount">Amount</p>
        </div>
        <div class="flex-grow"></div>
        <div class="right flex-column">
          <div id="right_amount" class="right flex-column" style="text-align:left;">
            <p id="amount_1" class="amount" if.bind="state.makePayment.sender.isBlockchain || (state.wallets && state.wallets[state.selectedAccountIndex] && state.wallets[state.selectedAccountIndex].currency === 'GBP')"> \${currency | currencyDenomination} \${amount}</p>
            <p id="amount_2" class="amount" if.bind="!state.makePayment.sender.isBlockchain && (!state.wallets || !state.wallets[state.selectedAccountIndex] || state.wallets[state.selectedAccountIndex].currency !== 'GBP')">\${amount} \${currency | currencyDenomination}</p>
          </div>
        </div>
      </div>

      <div id="TopUpChic" class="chic flex-column" if.bind="isTopUp">
        <div id="left_mybanka_fee" class="flex-row left">
          <p t="payments.recap.fee"></p>
          <p if.bind="state.me.isSubscribed">&nbsp;5%</p>
          <p if.bind="!state.me.isSubscribed">&nbsp;10%</p>
        </div>
        <div id="right_mybanka_fee" style="width: 100%; text-align: left;" if.bind="isTopUp">
          <p class="fee" if.bind="state.makePayment.sender.isBlockchain || (state.wallets && state.wallets[state.selectedAccountIndex] && state.wallets[state.selectedAccountIndex].currency === 'GBP')"> \${currency |currencyDenomination} \${feeAmount}</p>
          <p class="fee" if.bind="!state.makePayment.sender.isBlockchain && (!state.wallets || !state.wallets[state.selectedAccountIndex] || state.wallets[state.selectedAccountIndex].currency !== 'GBP')">-&nbsp;\${feeAmount} \${currency |currencyDenomination}</p>
        </div>
        <div id="right_topup_minerfee" if.bind="state.makePayment.sender.isBlockchain">
          <p class="fee" style="width: 100%; text-align: left;">\${currency |currencyDenomination} \${minerFee}</p>
        </div>
      </div>

      <div id="ChicLast" class="chic flex-column last">
        <p class="left" if.bind="!isTopUp" t="payments.recap.total" style="margin-top: .5rem">TOTAL</p>
        <p class="left" style="white-space: nowrap;" if.bind="isTopUp" t="payments.recap.topup.you_will_get"></p>
        <div class="flex-grow"></div>
        <div class="right flex-column" style="margin-top: .5rem;">
          <p id="amount_1" class="amount" if.bind="state.makePayment.sender.isBlockchain"> \${currency |currencyDenomination} \${total}</p>
          <p id="amount_bank_to_bank" class="amount" if.bind="!state.makePayment.sender.isBlockchain && !isTopUp">\${state.makePayment.amount | numberFormatEur:localeForCurrency} \${state.makePayment.currency | currencyDenomination}</p>
          <div id="amount_bank_to_bitcoin" class="flex-row" if.bind="isTopUp"> 
            <p class="amount" style="text-align: left; width: 100%;">BSV \${converted}</p>
          </div>
        </div>
      </div>

    </div>

    <div class="flex-grow" if.bind="displayError"></div>
    <span class="error" if.bind="displayError">\${errorMessage}</span>
    <div class="flex-grow"></div>
    <div class="spacer-32"></div>
    <div class="bubble flex-row" if.bind="!state.makePayment.sender.isBlockchain">
      <img src="${___HTML_LOADER_IMPORT_1___}" />
      <p class="info">
        The Regulated Payment Initiation Services are provided by<a
          class="info link"
          href="https://www.yapily.com/legal/yapilyconnect-terms-and-conditions/"
          >Yapily Connect</a
        >
      </p>
    </div>
    <div class="spacer-32"></div>
    <button click.delegate="createAuthPayments(e)" class="btn btn-primary" disabled.bind="isCreatingPayment || !isConverted && isTopUp">
      <span t="home.pay" if.bind="!isCreatingPayment"></span>
      <loader-ring class="loader-btn" if.bind="isCreatingPayment"></loader-ring>
    </button>
    <div class="spacer-default"></div>
  </section>
</template>
`;
// Exports
export default code;