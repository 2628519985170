import { Router } from "aurelia-router";
import { WalletsManager } from "services/WalletManager.service";
import { autoinject } from "aurelia-dependency-injection";
import { WalletInfo } from "services/WalletManager.service";
import { SingletonService } from "singleton";
import { P2PWallet } from "bsv-wallet";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { User } from "components/models/UserModel";
import { DialogService } from "aurelia-dialog";
// import { checkWidth } from "components/checkwidth/checkwidth";
import { UsersHttpClients } from "http_clients/UsersHttpClients";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { I18N } from "aurelia-i18n";
import { bindable } from 'aurelia-framework';
//const bsv = require("bsv");
import * as localForage from 'localforage';

@connectTo()
@autoinject()
export class Payments {
  private amount: number;
  private currentPage: string = "payment";
  private pageTitle: string = this.i18n.tr("bank.payment.make_payments");
  private state: State;
  private makePayment;
  private convertedAmount: number;
  private conversionTimer: any;
  private currency = "";
  private comment;
  private paymail: string = "";
  private infoPayment;
  private infoReceiver;
  private locked: boolean = false;
  private isPaymail: boolean = false;
  private displayError: boolean = false;
  private errorMessage: string = "";
  private myWalletID: Record<string, string>
  @bindable matchResult: boolean;

  constructor(
    private router: Router,
    private i18n: I18N,
    private store: Store<State>
  ) {}

  activate(
    {
      identification,
      comment,
      amount,
      currency,
      name,
    } = {} as any
  ) {
      this.infoReceiver = {identification,  name};
      this.infoPayment = {comment, amount, currency};
      this.amount = parseInt(amount);
      this.comment = comment;
      this.currency = currency;
      this.isItLocked();
  }

  isItLocked() {
    if (this.infoReceiver.identification != undefined && this.infoPayment.amount != undefined) {
      this.locked = true;
      this.store.dispatch('set.make_payment.recipient', this.infoReceiver);
      this.store.dispatch("set.make_payment.amount.and.currency", this.infoPayment.amount, this.infoPayment.currency);
      this.store.dispatch("set.make_payment.note", this.infoPayment.comment);
    }
    else {
      this.locked = false;
    }
  }

  bind() {
    this.delay();
    
  }

  delay() {
    setTimeout(() => (this.checkSender()), 3000);
  }

  checkSender() {
    this.state.makePayment.sender = this.state.wallets[this.state.selectedAccountIndex];
  }

  goToBitcoinImport(accountIdentification: string) {
    this.router.navigateToRoute("bitcoin_import", {selectedPaymail: accountIdentification});
  }

  async goToReviewPayments(singleWebSocketInput) {
    // Check if Bitcoin or Bank
    if(this.paymail){
      let account = {
        name: "",
        identification: this.paymail
      };
      this.store.dispatch('set.make_payment.recipient', account);
      this.currency = this.state.makePayment.sender.currency;
      this.store.dispatch(
        "set.make_payment.amount.and.currency",
        this.amount,
        this.currency
      );
      this.store.dispatch("set.make_payment.note", this.comment);
      this.router.navigateToRoute("payments_recap");
      return
    }
    if (this.state.makePayment.sender.isBlockchain && this.state.makePayment.recipient.identification.includes("@")) {
      // If Bitcoin, it checks if Sender Paymail matches with imported wallet seed
      this.getMatchingPaymail(singleWebSocketInput).then((result) => {
        if (this.matchResult === true){
          if(this.state.makePayment.sender.balance >= this.amount) {
            if (this.paymail && this.state.makePayment.sender.isBlockchain) {
              let account = {
                name: "",
                identification: this.paymail
              };
              this.store.dispatch('set.make_payment.recipient', account);
              this.currency = "BSV";
              this.store.dispatch(
                "set.make_payment.amount.and.currency",
                this.amount,
                this.currency
              );
              this.store.dispatch("set.make_payment.note", this.comment);
              console.log("1");
              this.router.navigateToRoute("payments_recap");
            }
            this.currency = this.state.makePayment.sender.currency;
            this.store.dispatch(
              "set.make_payment.amount.and.currency",
              this.amount,
              this.currency
            );
            this.store.dispatch("set.make_payment.note", this.comment);
            console.log("2");
            this.router.navigateToRoute("payments_recap");
          }
          else {
            this.displayError = true;
            if(!this.state.makePayment.recipient.identification.includes("@")) {
              this.errorMessage = this.i18n.tr("error.payments.selling_bitcoin") //"Selling Bitcoin is not supported yet."
            } else{        
              this.errorMessage = this.i18n.tr("error.payments.not_enough_cash") //"You don't have enough cash to make this payment"
            }
          }
        } else {
          this.displayError = true;
          this.errorMessage = this.i18n.tr("error.payments.wallet_not_on_device")
          //setTimeout(() => {this.goToBitcoinImport(this.state.makePayment.sender.identification)}, 5000);
        }
      })
    }  else if (!this.state.makePayment.sender.isBlockchain){
      // If Bank
        if(this.state.makePayment.sender.balance >= this.amount/100) {
          
          if (this.paymail && !this.state.makePayment.sender.isBlockchain) {
              this.currency = this.state.makePayment.sender.currency;
              console.log("this is currency: "+ this.currency)
              let account = {
                name: "",
                identification: this.paymail
              };
              this.store.dispatch('set.make_payment.recipient', account);
              this.store.dispatch(
                "set.make_payment.amount.and.currency",
                this.amount,
                this.currency
              );
              this.store.dispatch("set.make_payment.note", this.comment);
              console.log("3");
              this.router.navigateToRoute("payments_recap");
          }
          else if (this.state.makePayment.recipient.identification.includes("@") && this.amount < 100) {
            this.displayError = true;      
            this.errorMessage = this.i18n.tr("error.payments.minimum_amount")
          }
          else {
            this.currency = this.state.makePayment.sender.currency;
            this.store.dispatch(
              "set.make_payment.amount.and.currency",
              this.amount,
              this.currency
            );
            this.store.dispatch("set.make_payment.note", this.comment);
            console.log("4");
            this.router.navigateToRoute("payments_recap");
          }
          
        } else {
            this.displayError = true;      
            this.errorMessage = this.i18n.tr("error.payments.not_enough_cash") //"You don't have enough cash to make this payment"
        }
      }
      else {
        this.displayError = true;
        if(!this.state.makePayment.recipient.identification.includes("@")) {
          this.errorMessage = this.i18n.tr("error.payments.selling_bitcoin") //"Selling Bitcoin is not supported yet."
        } else{        
          this.errorMessage = this.i18n.tr("error.payments.not_enough_cash") //"You don't have enough cash to make this payment"
        }
      }
  }  

  goToChooseWallet() {
    this.store.dispatch("set.make_payment.mk", true);
    this.router.navigateToRoute("choose_wallet");
  }

  goToContacts() {
    this.store.dispatch("set.make_payment.mk", true);
    this.router.navigateToRoute("contacts", { page: "mine" });
  }

  startConversion(): void {
    clearTimeout(this.conversionTimer);
    this.conversionTimer = setTimeout(() => {
      this.convertAmount();
    }, 1000); // Attendre 500 ms avant de convertir
  }

  convertAmount(): void {
    this.amount /= 100000000;
  }

  async getMatchingPaymail(singleWebSocketPaymailInput: string) {
    this.myWalletID = JSON.parse(await localForage.getItem('wallets_' + this.state.me._id)) as Record<string, string>;
    if (this.myWalletID) {
      let match = false; // Initialize a variable to keep track of whether a match has been found

      for (let i = 0; i < Object.keys(this.myWalletID).length; i++) {
        const entryIndex = Object.keys(this.myWalletID)[i];
        const entryValues = Object.values(this.myWalletID)[i];
        const paymail = Object.keys(entryValues).includes(singleWebSocketPaymailInput)
        const seedPhrase =  Object.values(entryValues)

        if (!paymail) {match = false;}
        else 
        if (paymail) {
          match = true; // Set 'found' to true only when a match is found
          break; // Exit the loop since we have already found a match
        }
      }      
      this.matchResult = match;
    }
  }
}
