import { autoinject, computedFrom, inject } from "aurelia-framework";
import { AuthService } from "aurelia-auth";
import { Router } from "aurelia-router";
import { SingletonService } from "singleton";
import { YapilyPaymentRequestResponseModel } from "components/models/YapilyModels/YapilyPaymentRequestResponseModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { YapilyPaymentAuthRequest } from "components/models/YapilyModels/YapilyPaymentAuthRequests";
import { User } from "components/models/UserModel";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import { YapilyPayeeModel } from "components/models/YapilyModels/YapilyPayeeModel";
import { YapilyPayerModel } from "components/models/YapilyModels/YapilyPayerModel";
import { YapilyPaymentRequestModel } from "components/models/YapilyModels/YapilyPaymentRequestModel";
import { YapilyAccountIdentificationModel } from "components/models/YapilyModels/YapilyAccountIdentificationModel";
import * as IBAN from "iban";
import { RecipientModel } from "components/models/RecipientModel";
import { YapilyAccountModel } from "components/models/YapilyModels/YapilyAccountModel";
import { RecipientsSearchModel } from "components/models/RecipientsSearchModel";
import { observable, bindable, customElement } from "aurelia-framework";
import { FriendModel } from "components/models/FriendModel";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { EventAggregator } from "aurelia-event-aggregator";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";

import init, {
  Script,
  PrivateKey,
  Transaction,
  TxIn,
  ExtendedPrivateKey,
  Hash as WASMHash,
  SigHash,
  ExtendedPublicKey, Signature,
} from "bsv-wasm/bsv_wasm";
import localforage from "localforage";
import { I18N } from "aurelia-i18n";
import { getPublicProfile, requestP2PPaymentDestination, getCabapilities } from "services/paymailClient";

@connectTo()
@autoinject()
@customElement('calculator')
export class PaymentsRecap {
  private referenceStruct;
  private recipientNotSelectedError: boolean = false;
  private profilePic: boolean = false;
  private me: User;
  private currencyEuro: string = "EUR";
  private paymentsAuthInformation: YapilyPaymentAuthRequest =
    new YapilyPaymentAuthRequest();
  private isLoading: boolean;
  private accounts: YapilyAccountModel = new YapilyAccountModel();
  private isCommunicationTypeStructured: boolean = false;
  @observable private isCreatingPayment: boolean = false;
  private friendUrlPic;
  private myBankInfoForPayment;
  private state: State;
  private currency:string;
  private selectedRecipient;
  private typeOfPayments: string = "DOMESTIC_PAYMENT";
  private displayError: boolean = false;
  private errorMessage: string = "";
  private isTopUp: boolean = false;
  private profileToDisplay;
  private converted;
  private exchangeRate;
  walletManager: any;
  private formatAmount:any;

  private amount;
  private amountFormatted;
  private comment: string;
  private feeAmount;
  private feeAmountFormatted;
  private extraFee = 1;
  private minerFee;
  private minerFeeString: string = "0.00000001";
  private minerFeeNumber: number = 0.00000001;
  private total:string;
  @observable private isConverted: boolean = false;

  constructor(
    private singleton: SingletonService,
    private yapilyHttpClient: YapilyHttpClient,
    private paymailWalletHttpClient: PaymailWalletClient,
    private router: Router,
    private store: Store<State>,
    private ea: EventAggregator,
    private i18n: I18N
  ) {
    Date();
  }

  amountFormat(value: any): number | string {

    if (typeof value === 'number') {
      if(this.state.makePayment.sender.isBlockchain){
        this.formatAmount = (value).toFixed(8);
      } else if(!this.state.makePayment.sender.isBlockchain){
        this.formatAmount = (value).toFixed(2);
        }
        return this.formatAmount;
    } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
        if(this.state.makePayment.sender.isBlockchain){
          this.formatAmount = (parseFloat(value)).toFixed(8);
        } else if(!this.state.makePayment.sender.isBlockchain){
          this.formatAmount = (parseFloat(value)).toFixed(2);
        }
        return this.formatAmount
    } else {
      console.log("Error + Value :" + value + " Type of : " + typeof value )
    }
  }
  async bind() {
    this.profileToDisplay = this.state.makePayment.recipient.identification
    this.currency = this.state.makePayment.currency;

    if (this.currency === 'BSV') {
      this.amount = (this.state.makePayment.amount / 100000000).toFixed(8);
    } else {
      this.amountFormatted = parseFloat((this.state.makePayment.amount  / 100).toFixed(2));
      this.amount = this.amountFormat(this.amountFormatted);
    }

    this.minerFee = this.amountFormat(this.minerFeeNumber);
    this.feeAmount = this.addFees(this.amount);
    this.total = this.calculateTotal();
    this.comment = this.state.makePayment.note;
    this.selectedRecipient = this.state.makePayment.recipient;

    // if (this.selectedRecipient.identification.includes("@")  &&!this.state.makePayment.sender.isBlockchain && this.state.makePayment.recipient.isBlockchain){
    if (!this.state.makePayment.sender.isBlockchain && this.state.makePayment.recipient.identification.includes('@')){
      this.profileToDisplay = await getPublicProfile(this.state.makePayment.recipient.identification);
      this.isTopUp = true;
      this.getExchangeRate(this.amount , this.currency); //
    }
  }
  
  calculateTotal() {
    let parsedAmount = parseFloat(this.amount);
    // Needed for TopUp Fees and TopDown Fees later
    let parsedFeeAmount = parseFloat(this.feeAmount);
    let parsedMinerFee = parseFloat(this.minerFeeString);


    if (this.state.makePayment.sender.isBlockchain && this.currency === 'BSV'){
      return (parsedAmount + parsedMinerFee).toFixed(8);
    }
    if (!this.state.makePayment.sender.isBlockchain && this.currency === 'EUR') {
      const newTotal = (parsedAmount + parsedFeeAmount);
      return newTotal.toLocaleString("de-DE", { style: "decimal", minimumFractionDigits: 2 });
    }
    if (!this.state.makePayment.sender.isBlockchain && this.currency === 'USD') {
      return parsedAmount.toLocaleString("en-US", { style: "decimal", minimumFractionDigits: 2 });
    }
    if (!this.state.makePayment.sender.isBlockchain && this.currency === 'GBP') {
      return parsedAmount.toLocaleString("en-GB", { style: "decimal", minimumFractionDigits: 2 });
    }
  }

  async getTopAuthorisation(paymailUrl: string) {
    this.amount 
    this.getExchangeRate(this.amount, this.currency); //
    this.me = this.state.me;
    this.isLoading = true;
    const accountId = this.state.makePayment.sender.id;  
    const currency = this.state.makePayment.sender.currency;
    let result = this.convertirBTCenSatoshi(this.converted)

    let paymentDestinationResult = await requestP2PPaymentDestination(this.state.makePayment.recipient.identification, result)
    const response = await this.yapilyHttpClient.fetch("/exchange/create", {
      method: "POST",
      body: JSON.stringify({
        institutionId: this.state.makePayment.sender.institutionId, //ex: ing
        accountId,
        amountEurInCent: this.state.makePayment.amount,
        comment: this.comment,
        currency,
        displayName: this.me.displayName,
        paymail: this.state.makePayment.recipient.identification, //paymail
        reference: paymentDestinationResult.reference, //reference 
        outputs: paymentDestinationResult.outputs, // outputs 
        paymailUrl
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const { authorisationUrl, paymentIdempotencyId } = await response.json();
    localStorage.setItem("paymentIdempotencyId_toptup", paymentIdempotencyId);
    localStorage.setItem("ibanTopUp", this.state.makePayment.sender.identification);
    localStorage.setItem("currencyTopUp", this.state.makePayment.sender.currency);
    document.location = await authorisationUrl;
    this.isLoading = false;
  }

  async bsvPayment() {
    try {
      let walletId = this.state.makePayment.sender.id;
      let satoshisString = this.state.makePayment.amount;
      let satoshis = parseInt(satoshisString);
      let paymail = this.state.makePayment.recipient.identification;
      let request = await this.paymailWalletHttpClient.fetch(
        `/wallet/${walletId}/build`,
        {
          method: "POST",
          body: JSON.stringify({
            satoshis,
            paymail,
          }),
        }
      );

      let is200ok = await checkResponseStatus(request);
      let transactionBuild = await is200ok.json();
      let txCborHex = await this.signTransaction(transactionBuild);
      await this.submitAndSignedTransaction(transactionBuild, txCborHex);
    } catch(e) {
      this.displayError = true;
      this.errorMessage = this.i18n.tr("error.payments_recap.no_private_key")  //"No key on device";

    }
  }  

  async signTransaction(transactionBuild) {
    // Step 1: Initialize any necessary dependencies
    await init();

 // Step 2: Retrieve wallets from local storage based on user ID
  const wallets = JSON.parse(await localforage.getItem('wallets_' + this.state.me._id)) as Record<string, string>[];
  
  // Step 3: Get the current wallet's seed using the sender's identification
  
const senderIdentification = this.state.makePayment.sender.identification;
let currentWalletMnemonic: string | undefined;
  if (senderIdentification) {
      for (const wallet of wallets) {
          if (Object.keys(wallet)[0] === senderIdentification) {
              currentWalletMnemonic = wallet[senderIdentification];
              break;
          }
      }
  }
  
    // Step 4: Set mnemonic to current wallet's seed
    // Step 5: Derive private key from the mnemonic
    const xPriv = ExtendedPrivateKey.from_mnemonic(Buffer.from(currentWalletMnemonic, "utf8"));

    // Step 6: Extract transaction data from the provided transactionBuild
    const transaction = Transaction.from_compact_hex(transactionBuild.txCborHex);

    // Step 7: Iterate over transaction inputs
    const inputCount = transaction.get_ninputs();
    if (transactionBuild.xpubIndexes.length > inputCount)
      throw new Error("Too few transaction inputs...");
   
    for (let inputIndex = 0; inputIndex < transactionBuild.xpubIndexes.length; inputIndex++) {
      const privKey = xPriv
        .derive_from_path(
          "m/44/0/0/0/" + transactionBuild.xpubIndexes[inputIndex]
        )
        .get_private_key();
      const txIn = transaction.get_input(inputIndex);
      const sig = transaction.sign(
        privKey,
        SigHash.FORKID | SigHash.ALL,
        inputIndex,
        txIn.get_locking_script(),
        txIn.get_satoshis()
      );
      txIn.set_unlocking_script(
        Script.from_asm_string(
          `${sig.to_hex()} ${privKey.to_public_key().to_hex()}`
        )
      );
      transaction.set_input(inputIndex, txIn);
    }

    return transaction.to_compact_hex();
  }

  async submitAndSignedTransaction(transactionBuild, txCborHex) {
    try {
      let body = JSON.stringify({
        reference: transactionBuild.reference,
        satoshis: transactionBuild.satoshis,
        feeSatoshis: transactionBuild.feeSatoshis,
        paymail: this.state.makePayment.recipient.identification,
        note: this.comment,
        senderName: this.state.makePayment.sender.identification,
        txCborHex: txCborHex,
        changeOutput: transactionBuild.changeOutput,
        counterpartyUserId: "66068d686ef2c557938dfe55"
      })
      this.ea.publish("wallet.transaction.submit.one", body);
      this.router.navigateToRoute("home");
    } catch (e) {
      console.log(e);
      this.displayError = true;
      this.errorMessage = this.i18n.tr("error.payments_recap.no_private_key"); 
    }
  }

  async createAuthPayments() {
    await localforage.setItem('latestTransaction', this.state.makePayment);
    this.isCreatingPayment = true;

    if (!this.state.makePayment.sender.isBlockchain && this.state.makePayment.recipient.identification.includes("@")) {
      try {
        let capabilitiesResponse = await getCabapilities(this.state.makePayment.recipient.identification)
        if (capabilitiesResponse.capabilities['5f1323cddf31']) {
          let topUpUrl = capabilitiesResponse.capabilities['5f1323cddf31']
          await this.getTopAuthorisation(topUpUrl);
        }
        else {
          this.displayError = true;
          this.errorMessage = "Pas de capabilities sur le paymail utilisé"
        }
      } catch (e) {
        console.log("error: " + e);
      }
    }
    else if (!this.state.makePayment.sender.isBlockchain && !this.state.makePayment.recipient.identification.includes("@")) {
      try {
        this.paymentsAuthInformation = new YapilyPaymentAuthRequest();

      const currentBank = this.state.makePayment.sender;
      let payementInstitution;
      payementInstitution = currentBank.institutionId;
      this.paymentsAuthInformation.institutionId = payementInstitution;

      this.paymentsAuthInformation.paymentRequest =
        new YapilyPaymentRequestModel();
      this.paymentsAuthInformation.paymentRequest.amount = {
        currency: currentBank.currency,
        amount: this.amount,
      };
      this.paymentsAuthInformation.paymentRequest.payee =
        new YapilyPayeeModel();
      this.paymentsAuthInformation.paymentRequest.payer =
        new YapilyPayerModel();
      this.paymentsAuthInformation.paymentRequest.payee.accountIdentifications =
        new Array<YapilyAccountIdentificationModel>();
      this.paymentsAuthInformation.paymentRequest.payer.accountIdentifications =
        new Array<YapilyAccountIdentificationModel>();
      let currentPayerAccountIdentification: YapilyAccountIdentificationModel =
        new YapilyAccountIdentificationModel();

      let currentAccountidentification: YapilyAccountIdentificationModel =
        new YapilyAccountIdentificationModel();
      const typeOfIban = IBAN.isValid(this.state.makePayment.recipient.identification)
        ? "IBAN"
        : "BBAN";
      currentAccountidentification.type = typeOfIban;
      currentPayerAccountIdentification.type = typeOfIban;

      // Receiver
      currentAccountidentification.identification = this.state.makePayment.recipient.identification;
      currentPayerAccountIdentification.identification =
        currentBank.identification;
      /* Sender */
      this.paymentsAuthInformation.paymentRequest.payer.name =
        this.state.me.displayName;
      this.paymentsAuthInformation.paymentRequest.payer.accountIdentifications.push(
        currentPayerAccountIdentification
      );

      this.paymentsAuthInformation.paymentRequest.payee.accountIdentifications.push(
        currentAccountidentification
      );
      this.paymentsAuthInformation.paymentRequest.payee.name =
        this.selectedRecipient.name;
      this.paymentsAuthInformation.paymentRequest.reference =
        this.comment || "";
      this.paymentsAuthInformation.paymentRequest.type = this.typeOfPayments;
      this.postAuthPayment(this.paymentsAuthInformation);
      return true;
      } catch (e) {
        console.log("error: " + e);
      }
    }
    else if (this.state.makePayment.sender.isBlockchain && this.state.makePayment.recipient.identification.includes("@")) {
      try {
        await this.bsvPayment();
        return;
      } catch (e) {
        console.log("error: " + e);
      }
    }
  }

  //Topup part
  convertirBTCenSatoshi(montantBTC: number): number {
    const satoshiParBitcoin: number = 100000000;
    const montantSatoshi: number = Math.round(montantBTC * satoshiParBitcoin);
    return montantSatoshi;
  }
  
  //Yapily part
  async postAuthPayment(paymentInformation: YapilyPaymentAuthRequest) {
    try {
      this.isLoading = true;
      let httpRequest = await this.yapilyHttpClient.fetch(
        "/yapily/payment-auth-requests",
        {
          method: "POST",
          body: JSON.stringify(paymentInformation),
        }
      );

      let is200ok = await checkResponseStatus(httpRequest);
      let response: YapilyPaymentRequestResponseModel = await is200ok.json();
      this.paymentsAuthInformation.paymentRequest.paymentIdempotencyId =
        response.data.paymentIdempotencyId;
      this.singleton.setPaymentAuthInfomation(
        this.paymentsAuthInformation.paymentRequest
      );

      window.location.replace(response.data.authorisationUrl);
    } catch (error) {
      alert(JSON.stringify(error) + "erreur au post");
    }
  }

  async getExchangeRate(bsvinput: number , currency): Promise<any> { //
    let httpRequest = await this.yapilyHttpClient.fetch("/exchange/rate/" + currency); // 
    let isOk200 = await checkResponseStatus(httpRequest);
    let exchangeRate: any = await isOk200.json();
    this.exchangeRate = exchangeRate.ratesInUsd;
    this.converted = (parseFloat((bsvinput / this.exchangeRate).toFixed(8)) - parseFloat((this.feeAmount / this.exchangeRate).toFixed(8))).toFixed(8);
    this.isConverted = true;
  }

  addFees(value: any): number | string {

    if (typeof value === 'number') {
      if (this.state.me.isSubscribed === true) {
        if(this.state.makePayment.sender.isBlockchain){
          this.feeAmount = (value * 0.05).toFixed(8);
        } else if(!this.state.makePayment.sender.isBlockchain){
          this.feeAmount = (value * 0.05).toFixed(2);
        }
        return this.feeAmount
      } else if (this.state.me.isSubscribed === false) {
        if (this.state.makePayment.sender.isBlockchain){
          this.feeAmount = (value * 0.1).toFixed(8);
        } else if (!this.state.makePayment.sender.isBlockchain){
          this.feeAmount = (value * 0.1).toFixed(2);
        } 
        return this.feeAmount;
      }
    } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
      if (this.state.me.isSubscribed === true) {
        if(this.state.makePayment.sender.isBlockchain){
          this.feeAmount =  (parseFloat(value) * 0.05).toFixed(8);
        } else if(!this.state.makePayment.sender.isBlockchain){
          this.feeAmount =  (parseFloat(value) * 0.05).toFixed(2);
        }
        return this.feeAmount
      } else if (this.state.me.isSubscribed === false) {
        if (this.state.makePayment.sender.isBlockchain){
          this.feeAmount = (parseFloat(value) * 0.1).toFixed(8);
        } else if (!this.state.makePayment.sender.isBlockchain){
          this.feeAmount = (parseFloat(value) * 0.1).toFixed(2);
        }
        return this.feeAmount;
      }
    } else {
        throw new Error('Invalid input');
    }
  }
}
